import React, { useRef } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { Html } from "@react-three/drei"
import "./styles.css"

function Dodecahedron({ text1, text2, time, ...props }) {
  return (
    <mesh {...props}>
      <dodecahedronGeometry />
      <meshStandardMaterial roughness={0.75} emissive="#404057" />
      <Html distanceFactor={10}>
        <div class="content">
          {text1} <br />
          {text2}
        </div>
      </Html>
    </mesh>
  )
}

function Content() {
  const ref = useRef();
  useFrame(() => (ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z += 0.01))
  return (
    <group ref={ref}>
      <Dodecahedron text1={"Hi"} text2={"there,"} position={[-2, 0, 0]} />
      <Dodecahedron text1={"I"} text2={"am"} position={[0, -2, -3]} />
      <Dodecahedron text1={"Danny"} text2={"Mcwaves"} position={[2, 0, 0]} />
    </group>
  )
}

export default (
  <Canvas camera={{ position: [0, 0, 7.5] }}>
    <pointLight color="indianred" />
    <pointLight position={[10, 10, -10]} color="orange" />
    <pointLight position={[-10, -10, 10]} color="lightblue" />
    <Content />
  </Canvas>
)
