import { createRoot } from "react-dom/client";

import AnnotationsApp from "./annotations";
import BloomApp from "./bloom";
import LaptopApp from "./floating-laptop";
import GatsbyApp from "./gatsby-stars";
import RideWithDanny from "./learn-with-danny";

const items = [AnnotationsApp, BloomApp, LaptopApp, GatsbyApp, RideWithDanny];
const item = items[Math.floor(Math.random()*items.length)];

// display a random item
createRoot(document.getElementById("root")).render(item);
