import './styles.css'
import App from './App'

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <a href="https://blog.dannymcwaves.com/" target={"_blank"} style={{ position: 'absolute', bottom: 40, left: 40, fontSize: '25px' }}>
        Danny Mcwaves
        <br />
        <span style={{ fontSize: '12px', position: "relative", bottom: '10px' }}>Dev at large</span>
      </a>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate3d(-50%,-50%,0)' }}>
        <h1 style={{ margin: 0, padding: 0, fontSize: '15em', fontWeight: 500, letterSpacing: '-0.05em' }}>Welcome</h1>
      </div>
      <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>Contact me —</div>
      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>{new Date().toUTCString()}</div>
    </div>
  )
}

export default (
  <>
    <App /> <Overlay />
  </>
)
